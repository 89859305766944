/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';

import { useStaticQuery, graphql } from 'gatsby';
import { withThemeProvider } from '@mdc/services';

const SEO = (props) => {
    const description = props.description;
    const lang = props.lang;
    const meta = props.meta;
    const title = props.title;
    const theme = props.theme;
    const sitemapURL = props.sitemapURL;
    const { pathname } = useLocation();

    const { site, homepageImage } = useStaticQuery(
        graphql`
        query {
            site {
            siteMetadata {
                description
                author
            }
            }
            homepageImage: file(relativePath: {eq: "homepage.png"}) {
                publicURL
            }
        }
        `
    );


    const metaDescription = description || site.siteMetadata.description;
    const homepageImageUrl= homepageImage.publicURL;

    return (
        <Helmet
            htmlAttributes={{
                lang,
                class: theme || props.themeProvider.getActive()
            }}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: `MetaDefender Cloud | ${title}`,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:site_name',
                    content: 'MetaDefender Cloud'
                },
                {
                    property: 'og:image',
                    content: `${homepageImageUrl}`
                },
                {
                    property: 'og:url',
                    content: `https://metadefender.opswat.com${pathname}`
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: `MetaDefender Cloud | ${title}`,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'twitter:site',
                    content: '@OPSWAT'
                },
                {
                    name: 'twitter:url',
                    content: `https://metadefender.opswat.com${pathname}`
                },
                {
                    name: 'robots',
                    content: 'index,follow'
                }
            ].concat(meta)}
            title='MetaDefender Cloud'
            titleTemplate={`MetaDefender Cloud | ${title}`}
        >
            <link id={`MetaDefender Cloud | ${title}`} rel='sitemap' type='application/xml' href={sitemapURL} />
        </Helmet>
    );
};

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    script: [],
    description: '',
    sitemapURL: '/sitemap.xml'
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    script: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    themeProvider: PropTypes.any,
    theme: PropTypes.string,
    sitemapURL: PropTypes.string
};

export default withThemeProvider(SEO);
